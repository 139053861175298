import {
  computed,
} from '@vue/composition-api'
import useToast from '@/utils/toast'
import {
  syncronizeParamsWithUrl,
  replaceRouterQuery,
} from '@/utils/syncUrl'
import {
  values,
} from '@/utils/value'

import state from './state'
import api from './api'

export default () => {
  const { makeToast } = useToast()

  const searchParams = computed(() => ({
    page: state.currentPage,
    size: state.perPage,
    total: state.totalRows,
    ...state.filterParams,
  }))

  const categoryOptions = [
    // {
    //   label: 'CREATED_AT',
    //   paramName: 'created_at',
    //   format: 'date',
    // },
  ]

  const searchList = () => {
    state.isBusy = true
    return api.searchList({
      ...searchParams.value,
      sort_by: state.sortBy,
    }).then(response => {
      const resData = response.data
      state.widgetList = resData.data
      state.totalRows = resData.page_info.total_count
      state.filterParams = {
        ...state.filterParams,
        total: resData.page_info.total_count,
      }
    }).catch(() => {
      makeToast('danger', '목록을 쿼리하는데 실패 했습니다')
    }).finally(() => {
      state.isBusy = false
    })
  }

  const search = params => {
    state.currentPage = 1
    state.filterParams = {
      ...params,
    }
    syncronizeParamsWithUrl(searchParams.value)
    searchList()
  }

  const tableColumns = [
    { key: 'idx', label: 'idx', sortable: true },
    { key: 'type', label: 'type' },
    { key: 'name', label: 'name' },
    { key: 'state', label: 'state' },
    { key: 'created_at', label: 'created_at' },
  ]

  const changePage = params => {
    state.currentPage = params.page
    searchList()
  }

  const changeSort = ctx => {
    state.sortBy = ctx.sortDesc ? ctx.sortBy : `-${ctx.sortBy}`
    searchList()
  }

  return {
    categoryOptions,
    tableColumns,
    searchList,
    search,
    changePage,
    changeSort,
  }
}
