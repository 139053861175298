<template>
  <div>
    <TagbyFilterV2
      :initialParams="state.filterParams"
      :categoryOptions="categoryOptions"
      :leftWidth="3"
      @search="search"
    />
    <TagbyList
      v-model="state.currentPage"
      :items="getters.items"
      :fields="tableColumns"
      :busy="state.isBusy"
      :current-page="state.currentPage"
      :total-rows="state.totalRows"
      :per-page="state.perPage"
      @page-change="changePage"
      @sort-changed="changeSort"
    >
      <template #cell(idx)="data">
        <BLink
          :to="{ name: getRouterName(data.item.type), params: { idx: data.value} }"
        >
          {{ data.value }}
        </BLink>
      </template>

      <template #cell(state)="data">
        <div
          style="width: 80px"
          :class="data.value === 'ACTIVE' ? 'text-success' : null"
        >
          {{ data.value }}
        </div>
      </template>

      <template #cell(created_at)="data">
        <TagbyDatetimeColumn :value="data.value" />
      </template>
    </TagbyList>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BLink,
  BButton,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import useInitialize from './useInitialize'
import useItems from './useItems'
import TagbyFilterV2 from '@/components/TagbyFilterV2.vue'
import TagbyList from '@/components/TagbyList.vue'
import TagbyDatetimeColumn from '@/components/TagbyDatetimeColumn.vue'
import state from './state'
import getters from './getters'

export default defineComponent({
  components: {
    BLink,
    BButton,
    TagbyFilterV2,
    TagbyList,
    TagbyDatetimeColumn,
    BDropdown,
    BDropdownItem,
  },
  setup() {
    const {
      initialize,
    } = useInitialize()
    initialize()

    const {
      categoryOptions,
      hasRequestTime,
      tableColumns,
      search,
      searchList,
      changePage,
      changeSort,
    } = useItems()
    searchList()

    const getRouterName = widgetType => {
      if (widgetType === 'HOME_BANNER') {
        return 'app-manage-banner-re-detail'
      }
      if (widgetType === 'HOME_RECENT_PRODUCT') {
        return 'app-manage-recent-product-detail'
      }
      if (widgetType === 'TOP_FIXED_CAMPAIGN') {
        return 'app-manage-fixed-campaign-detail'
      }
      if (widgetType === 'RECOMMEND_CAMPAIGN') {
        return 'app-manage-recommend-campaign-detail'
      }
      if (widgetType === 'COMPT_CURATION') {
        return 'app-manage-compt-curation-detail'
      }
      if (widgetType === 'ALERT') {
        return 'app-manage-alert-detail'
      }
      if (widgetType === 'PROMOTION_MODAL') {
        return 'app-manage-promotion-modal-detail'
      }
      if (widgetType === 'LANDING_PATH') {
        return 'app-manage-landing-path-detail'
      }
      return ''
    }

    return {
      state,
      getters,
      categoryOptions,
      hasRequestTime,
      tableColumns,
      search,
      searchList,
      changePage,
      changeSort,
      getRouterName,
    }
  },
})
</script>
